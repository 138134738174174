<template>
   <div class="contain">
      <div class="title">Time-limited Auction Rules</div>
      <div class="page">
         <div class="mb-12">
            <div class="mb-6">1. Starting price</div>
            <div class="mb-8">
             The minimum price for this auction item, bids below this amount will not be accepted.
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">2. Reserve price mechanism</div>
            <div class="mb-1">
              If the reserve price is set: The final bid exceeding the reserve price will be accepted. 
                 </div>
            <div class="mb-8">
              If the reserve price is not set: After the auction period ends, the final bid will be accepted, and the highest bidder gets the NFT.
              
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">3. Buy it now price</div>
            <div class="mb-8">
              When the bid exceeds the buy it now price, the NFT is sold at the bid price and the auction ends immediately.
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">4. Withdrawal mechanism</div>
            <div class="mb-8">
            You may withdraw your auctioned item before receiving a valid bid; Auctions cannot be canceled after a valid bid has been placed.
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">5. Minimum markup limit</div>
            <div class="mb-8">
               Your bid needs to be at least 5% higher than the current bid, and your bid amount will be locked immediately. Once a higher bid appears, the locked bid amount will be released. 
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">6. Time delay mechanism</div>
            <div class="mb-8">
             Any bids made within the last 10 minutes of the auction period will extend the auction by 10 minutes.
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">7. Removal mechanism</div>
            <div class="mb-8">
             After the auction period ends, any NFT that has not received any bids will be considered unsold.
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">8. Auction accepted mechanism</div>
            <div class="mb-1">
               After the auction period ends,  the platform will implement the
               following rules:
            </div>
            <div class="mb-1">
               · The reserve price is set, and the final bid exceeds the reserve price: the platform executes the “Deal” operation.
            </div>
            <div class="mb-1">
               · The reserve price is set, but the final bid lower than the reserve price: the platform executes the "Abandon" operation.
            </div>
            <div class="mb-8">
               · Not set the reserve price: the platform executes the "Deal" operation.
            </div>
         </div>

      </div>
      <div class="title">Frequently Asked Questions</div>
      <div class="page">
         <div class="mb-12">
            <div class="mb-6">1. How to start an auction?</div>
            <div class="mb-8">
             When you place your NFT in an auction, you need to set a starting price, starting time and auction length. You can also choose to set a reserve price and buy it now price.
            </div>
            <div class="mb-1">· Starting price: Bids below this amount will not be accepted.</div>
            <div class="mb-1">· Reserve price: After the auction ends, the final bid above the reserve price will be accepted. 
            </div>
            <div class="mb-8">· Buy it now price: When the bid exceeds the buy it now price, the auction will immediately end.
            </div>
            <div class="mb-1">Please note that：</div>
            <div class="mb-8">· Auctions cannot be cancelled after a valid bid has been placed. Any bid placed in the last 10 minutes will extend the auction by a further 10 minutes.</div>
         </div>
         <div class="mb-12">
            <div class="mb-6">2. How to place a bid?</div>
            <div class="mb-1">If you want to place a bid, you must meet the following conditions:</div>
            <div class="mb-1">
              · Bid must be higher than the starting price
            </div>
            <div class="mb-8">
            · Bid must be at least 5% higher than the current bid
            </div>
         
         </div>
         
      </div>
   </div>
</template>

<script>
  export default {
      mounted() {
         window.eventBus.$on('LangChange', this.onLangChange);
      },
      beforeDestroy() {
         window.eventBus.$off("LangChange", this.onLangChange);
      },
      methods: {
         onLangChange() {
            if(this.$vuetify.lang.current == 'en'){
               this.$router.push({name:'Auctionruleen'})
            }else if(this.$vuetify.lang.current == 'zh'){
               this.$router.push({name:'Auctionrulezh'})
            }else if(this.$vuetify.lang.current == 'ja'){
               this.$router.push({name:'Auctionruleen'})
            }else if(this.$vuetify.lang.current == 'ko'){
               this.$router.push({name:'Auctionruleen'})
            }else if(this.$vuetify.lang.current == 'pt'){
               this.$router.push({name:'Auctionruleen'})
            }
         }
      }
  }
</script>

<style lang="scss" scoped>
   .contain {
      max-width: 856px;
      margin: 0 auto;

      .title {
         margin-top: 80px;
         margin-bottom: 50px;
         font-weight: bold;;
         font-size: 55px !important;
         font-weight: bold;
         line-height: 60px;
         font-stretch: normal;
         letter-spacing: 0px;
         color: #270645;
        
      }

      .page {
         .mb-12 {
            .mb-6 {
               font-weight: bold;;
               font-size: 30px;
               font-weight: bold !important;
               font-stretch: normal;
               letter-spacing: 0px;
               color: #270645;
            }
            .mb-8,.mb-1{
               font-family: Helvetica;
               font-size: 16px;
               font-weight: normal;
               font-stretch: normal;
               letter-spacing: 0px;
               color: #270645;
            }
         }
      }
   }
</style>
